@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
.dark body {
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*/GRADIENTS/*/
.gradient-45 {
  background: #d728d7;
  background: linear-gradient(
    45deg,
    #d7282f,
    #d72880 33.33%,
    #d728d7 66.66%,
    #8028d7 100%
  );
}

input[type="range"]::-webkit-slider-thumb {
  width: 0.75rem;
  -webkit-appearance: none;
  appearance: none;
  height: 0.75rem;
  cursor: ew-resize;
  background: white;
  box-shadow: -405px 0 0 400px #6366f1;
  border: 1px solid #9ca3af;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.dark input[type="range"]::-webkit-slider-thumb {
  background: black;
  border: 1px solid #4b5563;
}

/*LIVE CHAT BUTTON POSITION*/
#designstudio-button {
  bottom: 4rem !important;
  z-index: 30 !important;
}

/*LENDERS LOGOS*/
._1,
._105,
._115,
._116,
._120,
._122,
._123,
._129,
._132,
._140,
._142,
._15,
._154,
._156,
._159,
._163,
._168,
._17,
._175,
._181,
._183,
._187,
._189,
._190,
._198,
._207,
._208,
._220,
._23,
._230,
._245,
._259,
._267,
._274,
._278,
._28,
._284,
._297,
._30,
._31,
._313,
._332,
._344,
._350,
._351,
._360,
._37,
._393,
._40,
._42,
._429,
._48,
._50,
._503,
._51,
._523,
._53,
._54,
._557,
._560,
._57,
._585,
._595,
._596,
._598,
._6,
._61,
._619,
._627,
._628,
._63,
._65,
._66,
._71,
._711,
._724,
._74,
._75,
._76,
._769,
._77,
._786,
._791,
._796,
._80,
._803,
._81,
._816,
._82,
._821,
._828,
._839,
._86,
._90,
._95,
._760,
._400,
._114,
._802,
._411,
._59,
._733,
._565,
._937,
._431,
._837,
._493,
._714,
._349,
._838,
._846,
._676,
._797,
._14,
._178,
._130,
._840,
._623,
._695,
._256,
._478,
._513,
._765,
._204,
._514,
._607,
._326,
._826,
._24,
._901,
._84,
._942,
._346,
._482,
._662,
._489,
._112,
._358,
._428,
._68,
._656,
._219,
._893,
._92,
._661,
._686,
._597,
._536,
._507,
._642,
._425,
._537,
._935,
._327,
._10,
._845,
._667,
._758,
._634,
._87,
._900,
._874,
._334,
._223,
._347,
._321,
._316,
._649,
._83,
._1012,
._1013,
._1014,
._1008,
._1009,
._1007,
._996,
._885,
._985,
._860,
._863,
._866,
._613,
._64,
._1015,
._898,
._934,
._1026,
._927,
._495,
._408,
._958,
._1058,
._466,
._693,
._141,
._955,
._737,
._696,
._1068,
._1064,
._669,
._45,
._957,
._1048,
._1084,
._1089,
._1097,
._1021,
._1023,
._311,
._603,
._605,
._111,
._678,
._1031,
._240,
._1038,
._126,
._1040,
._1043,
._1047,
._1054,
._1050,
._1052,
._389,
._146,
._1056,
._993,
._1061,
._1062,
._1063,
._1065,
._1072,
._282,
._1075,
._1076,
._1078,
._635,
._1079,
._1080,
._1081,
._1082,
._206,
._497,
._609,
._1088,
._330,
._1090,
._1091,
._374,
._1094,
._1095,
._1099,
._1093,
._708,
._548,
._639,
._1139,
._1140,
._1222,
._869,
._1174,
._848,
._851,
._1175,
._847,
._139,
._1235,
._1335,
._1193,
._1342,
._1314,
._1227,
._1228,
._1352,
._1367,
._1346,
._1141,
._1369,
._1083,
._172,
._1375,
._1355 {
  /* display: inline-block; */
  background: url("./img/LenderLogos.png") no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  width: 80px;
  height: 60px;
  /* margin-top: 8px;
  margin-bottom: 8px; */
}

._1,
._693,
._141,
._311,
._603,
._605,
._126 {
  /*Santander*/
  background-position: -1px 0;
}

._105,
._536,
._507,
._935 {
  /*Tipton*/
  background-position: -163px 0;
}

._115,
._374 {
  /*Barclays*/
  background-position: -244px 0;
}

._116 {
  /*Yorkshire BS*/
  background-position: -325px 0;
}

._120,
._628,
._346,
._1089,
._1043 {
  /*Bank Of Ireland*/
  background-position: -406px 0;
}

._122,
._358 {
  /*Ulster Bank*/
  background-position: -487px 0;
}

._123 {
  /*BM Solutions*/
  background-position: -568px 0;
}

._129,
._313 {
  /*TSB*/
  background-position: -649px 0;
}

._132 {
  /*Teachers BS*/
  background-position: -1px -61px;
}

._140,
._111,
._139,
._1235 {
  /*Skipton*/
  background-position: -82px -61px;
}

._142 {
  /*Lloyds Bank*/
  background-position: -163px -61px;
}

._15,
._1082 {
  /*Cambridge BS*/
  background-position: -244px -61px;
}

._154,
._344 {
  /*HSBC*/
  background-position: -325px -61px;
}

._156 {
  /*Scottish BS*/
  background-position: -406px -61px;
}

._159,
._112,
._83,
._1012 {
  /*Pepper*/
  background-position: -487px -61px;
}

._163 {
  /*Clydesdale Bank*/
  background-position: -568px -61px;
}

._168 {
  /*TMW*/
  background-position: -649px -61px;
}

._17 {
  /*Chelsea BS*/
  background-position: -1px -122px;
}

._175,
._1075 {
  /*Vernon*/
  background-position: -82px -122px;
}

._181 {
  /*Scottish Widows*/
  background-position: -163px -122px;
}

._183,
._634,
._282,
._1078,
._1090 {
  /*Buckinghamshire BS*/
  background-position: -244px -122px;
}

._187 {
  /*Earl Shilton*/
  background-position: -325px -122px;
}

._189,
._669 {
  /*Stafford Railway BS*/
  background-position: -406px -122px;
}

._190,
._874 {
  /*Bath BS*/
  background-position: -487px -122px;
}

._198,
._327 {
  /*Furness*/
  background-position: -568px -122px;
}

._207,
._619,
._816 {
  /*Natwest*/
  background-position: -649px -122px;
}

._208,
._10,
._737 {
  /*Hanley*/
  background-position: -1px -183px;
}

._220,
._597,
._537,
._1026,
._330 {
  /*Kensington*/
  background-position: -82px -183px;
}

._23 {
  /*Progressive BS*/
  background-position: -163px -183px;
}

._230 {
  /*Bank Of China*/
  background-position: -244px -183px;
}

._245,
._598,
._937,
._656,
._219,
._1056,
._497,
._609,
._708 {
  /*Paragon Bank*/
  background-position: -325px -183px;
}

._259 {
  /*Yorkshire Bank*/
  background-position: -406px -183px;
}

._267 {
  /*First Trust*/
  background-position: -487px -183px;
}

._274,
._851 {
  /*Bluestone*/
  background-position: -568px -183px;
}

._278,
._996,
._000 {
  /*New Street*/
  background-position: -649px -183px;
}

._28 {
  /*Coventry BS*/
  background-position: -1px -244px;
}

._284,
._513,
._765 {
  /*Shawbrook Bank*/
  background-position: -82px -244px;
}

._297,
._667 {
  /*Chorley BS*/
  background-position: -163px -244px;
}

._30,
._334 {
  /*Darlington BS*/
  background-position: -244px -244px;
}

._31 {
  /*RBS*/
  background-position: -325px -244px;
}

._482 {
  /*AIB Bank*/
  background-position: -406px -244px;
}

._332,
._84,
._1013,
._885,
._985,
._993,
._1079,
._1094,
._1346 {
  /*Together*/
  background-position: -487px -244px;
}

._662 {
  /*Haven Mortgages*/
  background-position: -568px -244px;
}

._350 {
  /*Hodge Bank*/
  background-position: -649px -244px;
}

._351,
._623,
._695,
._68,
._649,
._1008,
._1009,
._696,
._206,
._639,
._848,
._1367 {
  /*Precise Mortgages*/
  background-position: -1px -305px;
}

._360 {
  /*Accord Mortgages*/
  background-position: -82px -305px;
}

._37 {
  /*Godiva Mortgages*/
  background-position: -163px -305px;
}

._393,
._1038,
._1076,
._1080,
._1081,
._548,
._847,
._1314 {
  /*The Mortgage Lender*/
  background-position: -244px -305px;
}

._40,
._769 {
  /*Platform*/
  background-position: -325px -305px;
}

._42,
._321,
._316 {
  /*Halifax*/
  background-position: -406px -305px;
}

._429,
._898 {
  /*Landbay*/
  background-position: -487px -305px;
}

._48 {
  /*Ecology*/
  background-position: -649px -305px;
}

._50,
._1088 {
  /*Hinckley & Rugby*/
  background-position: -1px -366px;
}

._503,
._92 {
  /*Tesco Bank*/
  background-position: -82px -366px;
}

._51 {
  /*Holmesdale BS*/
  background-position: -163px -366px;
}

._523,
._1014,
._863,
._240,
._1174 {
  /*Vida Homeloans*/
  background-position: -244px -366px;
}

._53 {
  /*Suffolk B Society*/
  background-position: -325px -366px;
}

._54,
._1007 {
  /*Kent Reliance*/
  background-position: -406px -366px;
}

._557,
._14,
._178 {
  /*Norton*/
  background-position: -487px -366px;
}

._560,
._686,
._1068 {
  /*The West Brom*/
  background-position: -568px -366px;
}

._57,
._642,
._425,
._934,
._635 {
  /*Leeds BS*/
  background-position: -649px -366px;
}

._585 {
  /*Airdrie Savings Bank*/
  background-position: -1px -427px;
}

._595 {
  /*Aldermore*/
  background-position: -82px -427px;
}

._596 {
  /*State Bank Of India*/
  background-position: -163px -427px;
}

._493,
._676,
._797,
._901,
._1040,
._1054,
._1052 {
  /*Masthaven*/
  background-position: -244px -427px;
}

._6 {
  /*Beverley BS*/
  background-position: -325px -427px;
}

._61,
._1015 {
  /*The Loughborough BS*/
  background-position: -406px -427px;
}

._489 {
  /*KBC Homeloans */
  background-position: -487px -427px;
}

._627 {
  /*MBS*/
  background-position: -568px -427px;
}

._431,
._714,
._349 {
  /*Oplo*/
  background-position: -649px -427px;
}

._63,
._87,
._860,
._1047 {
  /*The Mansfield BS*/
  background-position: -1px -488px;
}

._65 {
  /*Marsden BS*/
  background-position: -82px -488px;
}

._66 {
  /*The Melton BS*/
  background-position: -163px -488px;
}

._71,
._1050 {
  /*Monmouthshire BS*/
  background-position: -244px -488px;
}

._711 {
  /*First Direct*/
  background-position: -325px -488px;
}

._724,
._678,
._1031,
._146 {
  /*Metro Bank*/
  background-position: -406px -488px;
}

._74,
._1065,
._1222,
._1342 {
  /*The Family BS*/
  background-position: -487px -488px;
}

._75 {
  /*Nationwide BS*/
  background-position: -568px -488px;
}

._76 {
  /*Newbury BS*/
  background-position: -649px -488px;
}

._428 {
  /*Permanent TSB*/
  background-position: -1px -549px;
}

._77,
._758 {
  /*Newcastle BS*/
  background-position: -82px -549px;
}

._786,
._389,
._869 {
  /*Foundation Home Loans*/
  background-position: -163px -549px;
}

._791,
._866,
._1091,
._1095 {
  /*Magellan*/
  background-position: -244px -549px;
}

._796 {
  /*Fleet Mortgages*/
  background-position: -325px -549px;
}

._80,
._900 {
  /*Virgin Money*/
  background-position: -406px -549px;
}

._803,
._1021,
._1023 {
  /*Leek United BS*/
  background-position: -487px -549px;
}

._81 {
  /*Norwich & Peterborough BS*/
  background-position: -568px -549px;
}

._59,
._613 {
  /*Post Office*/
  background-position: -649px -549px;
}

._942 {
  /*BlueZest*/
  background-position: -1px -610px;
}

._82,
._845 {
  /*The Nottingham BS*/
  background-position: -82px -610px;
}

._821 {
  /*Axis Bank*/
  background-position: -163px -610px;
}

._828,
._223,
._347,
._1175 {
  /*Dudley BS*/
  background-position: -244px -610px;
}

._839 {
  /*Keystone*/
  background-position: -325px -610px;
}

._86,
._1064 {
  /*Penrith BS*/
  background-position: -406px -610px;
}

._90,
._1072,
._1099 {
  /*Principality*/
  background-position: -487px -610px;
}

._95 {
  /*Saffron BS*/
  background-position: -568px -610px;
}

._114 {
  /*Swansea BS*/
  background-position: -649px -610px;
}

._760,
._1061,
._1062,
._1063 {
  /*Secure trust Bank*/
  background-position: -1px -671px;
}

._400,
._607,
._326,
._1083 {
  /*Central Trust*/
  background-position: -82px -671px;
}

._802,
._130,
._840 {
  /*Optimum Credit*/
  background-position: -244px -671px;
}

._411,
._256,
._478 {
  /*Prestige*/
  background-position: -325px -671px;
}

._733,
._204,
._514 {
  /*StepOne Finance*/
  background-position: -406px -671px;
}

._565,
._826,
._24,
._1097,
._1228 {
  /*United Trust Bank*/
  background-position: -487px -671px;
}

._837,
._838,
._846 {
  /*Evolution Money*/
  background-position: -568px -671px;
}

._893 {
  /*Bank Of Cyprus (UK)*/
  background-position: -649px -671px;
}

._661 {
  /*Danske Bank*/
  background-position: -1px -732px;
}

._64 {
  /*Market Harborough*/
  background-position: -82px -732px;
}

._927 {
  /*Equifinance*/
  background-position: -163px -732px;
}

._495 {
  /*Al Rayan Bank*/
  background-position: -244px -732px;
}

._408 {
  /*Digital Mortgages*/
  background-position: -325px -732px;
}

._958,
._957 {
  /*West One*/
  background-position: -406px -732px;
}

._1058 {
  /*Coutts Bank*/
  background-position: -487px -732px;
}

._466 {
  /*Sainsbury's Bank*/
  background-position: -568px -732px;
}

._955 {
  /*M&S Bank*/
  background-position: -649px -732px;
}

._45 {
  /*Harpenden*/
  background-position: -1px -793px;
}

._1048,
._1093 {
  /*Zephyr Homeloans*/
  background-position: -82px -793px;
}

._1084 {
  /*Lend Invest*/
  background-position: -163px -793px;
}

._1139,
._1140 {
  /*Selina Finance + (Ltd)*/
  background-position: -244px -793px;
}

._1335 {
  /*Generation Home*/
  background-position: -325px -793px;
}

._1193 {
  /*Habito*/
  background-position: -406px -793px;
}
._1227 {
  /*L+G Home Finance  */
  background-position: -487px -793px;
}
._1352 {
  /*Reliance Bank*/
  background-position: -568px -793px;
}
._1141,
._1369 {
  /*MPowered Mortgages*/
  background-position: -649px -793px;
}
._172,
._1355 {
  /*CHL Mortgages*/
  background-position: -1px -854px;
}
._1375 {
  /*Tandem Bank*/
  background-position: -82px -854px;
}
